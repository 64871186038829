/* Google Fontsのインポート */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif; /* フォントをRobotoに設定 */
  scroll-behavior: smooth; /* スクロールを滑らかに */
  background: linear-gradient(to bottom, hsl(0, 0%, 20%), #111); /* グラデーションの背景色 */
  color: #fff; /* テキストカラーを白に設定 */
}

a {
  text-decoration: none;
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section {
  height: 100vh; /* 各セクションの高さを100vhに設定 */
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start; /* スクロールスナップの開始位置を設定 */
  scroll-snap-stop: always; /* スクロールスナップの停止を強制 */
}

html {
  scroll-snap-type: y mandatory; /* 縦方向のスクロールスナップを有効に */
  scroll-padding-top: 1rem; /* スクロールスナップのパディングを追加 */
}

/* 特定のセクションで横スクロールを有効に */
#projects {
  scroll-snap-type: x mandatory; /* 横方向のスクロールスナップを有効に */
  overflow-x: scroll; /* 横スクロールを有効に */
  display: flex;
  flex-wrap: nowrap;
  scroll-padding-left: 1rem; /* スクロールスナップのパディングを追加 */
}
